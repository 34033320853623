import React from 'react';
import Text from '@clearscore/ui.rainbow.text';
import { useTranslation } from 'react-i18next';

import IconClock from './clock--timer.svg';
import styles from './author-bio.module.css';
import { PAGE_NAMESPACE } from '../../lib/contants';

interface ISummaryProps {
    version: string;
    effectiveFrom: string;
    timeToRead: number;
}

const Summary = ({ version, effectiveFrom, timeToRead }: ISummaryProps): React.ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);

    return (
        <div itemProp="summary" className={styles.authorBio} data-qa="summary">
            <span className={styles.alignCenter}>
                <Text type={Text.types.TINY}>
                    <span itemProp="datePublished">{t('version', { version })}</span>
                    <span className={styles.dotDivider} itemProp="name">
                        {t('effective', { from: effectiveFrom })}
                    </span>
                    <span data-qa="article-timeToRead" className={styles.dotDivider}>
                        <IconClock className={styles.icon} width={16} height={16} />{' '}
                        {t('minRead', { time: timeToRead })}
                    </span>
                </Text>
            </span>
        </div>
    );
};

export default Summary;
