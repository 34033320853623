import React, { Fragment, useEffect } from 'react';
import ShortCodes from '@clearscore/ui.shared.markdown-shortcodes';

declare global {
    interface Window {
        EMBED_PARAMS: {
            surveyID: number;
            domain: string;
            src: string;
            width: string;
            height: null;
            border: string;
        };
    }
}

const PersonalityQuiz = () => {
    const [surveyId, updateSurveyId] = React.useState<string | undefined>();

    useEffect(() => {
        window.EMBED_PARAMS = {
            surveyID: 1602658019,
            domain: '//eu.questionpro.com',
            src: '//eu.questionpro.com/a/TakeSurvey?tt=J4rwE0rPpasT5yr9vr91vg%3D%3D',
            width: '100%',
            height: null,
            border: 'hidden',
        };
        updateSurveyId(window.EMBED_PARAMS.surveyID.toString());
    }, []);
    return (
        <Fragment>
            <style
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{
                    __html: `
            #div_${surveyId} iframe {
                min-height: 1400px;
            }

            @media only screen and (min-width: 600px) {
                #div_${surveyId} iframe {
                    min-height: 1000px;
                }
            }
            `,
                }}
            />
            <div id={`div_${surveyId}`} style={{ minHeight: '500px' }} />
            {surveyId && (
                <ShortCodes.script src="//eu.questionpro.com/javascript/embedsurvey.js?version=1" id={surveyId} />
            )}
        </Fragment>
    );
};

export default PersonalityQuiz;
