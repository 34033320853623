import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import Legal from '@clearscore/website.legal';

import Layout from '../components/layout';

interface ILegalPageContext {
    pages: Array<{ href: string; label: string }>;
    isWebView?: boolean;
}

const LegalTemplate = ({
    data,
    pageContext: { pages, isWebView },
    location,
}: PageProps<Queries.Query, ILegalPageContext>): React.ReactElement => {
    const {
        childMdx,
        meta,
        language: { title },
        effectiveFrom,
        version,
    } = data.legalPages;
    const { body, timeToRead, wordCount, tableOfContents } = childMdx;

    const remappedPages = pages.map(({ href, label }) => ({ url: href, title: label }));
    return (
        <Layout meta={meta} location={location.pathname}>
            {({ commonModules }): React.ReactElement => (
                <Legal
                    isWebView={isWebView}
                    pages={remappedPages}
                    version={version}
                    pageConfig={{
                        timeToRead,
                        wordCount: wordCount.words,
                        // @ts-expect-error fix after migration to TS
                        tableOfContents,
                    }}
                    effectiveFrom={effectiveFrom}
                    language={{
                        title,
                        content: body,
                    }}
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={commonModules.cookie_policy}
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query Legal($slug: String, $locale: String, $version: String) {
        legalPages(slug: { eq: $slug }, locale: { eq: $locale }, version: { eq: $version }) {
            childMdx {
                timeToRead
                wordCount {
                    words
                }
                tableOfContents(maxDepth: 2)
                body
            }
            version
            effectiveFrom
            language {
                title
            }
            meta {
                title
                description
                url
            }
        }
    }
`;

export default LegalTemplate;
