import React, { Fragment } from 'react';
import ShortCodes from '@clearscore/ui.shared.markdown-shortcodes';

const BalanceTransferCalculator = () => (
    <Fragment>
        <div className="calconic-calculator" data-calculatorid="5ddd090b8684ea001eb018f1" />
        <ShortCodes.script
            src="https://cdn.calconic.com/beta/js/calconic.min.js"
            id="calconic_"
            async
            dataset-calconic="true"
        />
    </Fragment>
);

export default BalanceTransferCalculator;
