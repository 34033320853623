import React, { Fragment } from 'react';
import ShortCodes from '@clearscore/ui.shared.markdown-shortcodes';

const BalanceTransferCalculatorAU = () => (
    <Fragment>
        <div className="calconic-calculator" data-calculatorid="5e7d7759ae3013001ef36922" />
        <ShortCodes.script src="https://cdn.calconic.com/static/js/calconic.min.js" id="calconic_" />
    </Fragment>
);
export default BalanceTransferCalculatorAU;
