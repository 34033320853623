import React from 'react';

const CarInsuranceQuiz = () => (
    <iframe
        src="https://clearscore205.outgrow.us/5d517bc65b5fed514f653a5d"
        title="Car insurance trivia quiz?"
        scrolling="yes"
        width="100%"
        height="600"
    />
);

export default CarInsuranceQuiz;
