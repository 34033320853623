import type { ReactElement } from 'react';
import React from 'react';
import cx from 'classnames';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Link from 'gatsby-link';
import { MDXProvider } from '@mdx-js/react';
import Text from '@clearscore/ui.rainbow.text';
import Template from '@clearscore/shared.website-template';
import ShortCodes from '@clearscore/ui.shared.markdown-shortcodes';
import ArticleTools from '@clearscore/shared.website-article-tools';
import { useTranslation } from 'react-i18next';
import type { IAppStoreConfig } from '@clearscore/shared.website-app-logos';

import ScrollProgressBar from './components/scroll-progress-read';
import Summary from './components/author-bio';
import Hr from './components/hr';
import TableOfContents from './components/table-of-contents';
import styles from './legal.module.css';
import spacing from './spacing.module.css';
import { PAGE_NAMESPACE } from './lib/contants';

const getMarkdownOverrides = () => ({
    h2: ({ children, ...rest }: { children: ReactElement }) => (
        <div className={styles.navOffset}>
            <ShortCodes.h2 {...rest}>{children}</ShortCodes.h2>
        </div>
    ),
});

interface ICategoryHeaderProps {
    to?: string;
    isWebView: boolean;
    children: string;
}

const CategoryHeader = ({ children = '', to, isWebView }: ICategoryHeaderProps): ReactElement => {
    const Header = (
        <Text.H6 tag={Text.tags.P} dataId="article-category">
            {children}
        </Text.H6>
    );

    return <div className={styles.categoryHeader}>{to && !isWebView ? <Link to={to}>{Header}</Link> : Header}</div>;
};

interface ILegalArticleProps {
    language: Record<string, string>;
    targetRef: React.RefObject<HTMLDivElement>;
    version: string;
    effectiveFrom: string;
    tableOfContents: unknown[];
    timeToRead: number;
    isWebView: boolean;
    pages: unknown[];
}

const LegalArticle = ({
    language,
    targetRef,
    version,
    effectiveFrom,
    tableOfContents,
    timeToRead,
    isWebView,
    pages,
}: ILegalArticleProps): ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    return (
        <article data-layout="full-bleed" className={styles.article} itemScope itemType="http://schema.org/Article">
            <Template.Grid>
                <div data-layout="article-left" className={styles.primaryCta}>
                    <TableOfContents
                        isId
                        header={t('contentsTitle')}
                        tableOfContents={tableOfContents}
                        data-qa="table-of-contents-desktop"
                    />
                </div>
                <div data-layout="article-right" className={styles.primaryCta}>
                    <TableOfContents
                        header={t('otherLegals')}
                        tableOfContents={pages}
                        data-qa="table-of-contents-desktop"
                    />
                </div>
                <div data-layout="article-content" className={styles.content} ref={targetRef}>
                    <div data-layout="article-content">
                        <CategoryHeader isWebView={isWebView}>Legal Pages</CategoryHeader>
                        <div className={cx(spacing.medium, styles.pageTitle)} data-qa="pageTitle" itemProp="headline">
                            <Text.H1>{language.title}</Text.H1>
                        </div>
                        <div className={cx(spacing.big)}>
                            <Summary timeToRead={timeToRead} version={version} effectiveFrom={effectiveFrom} />
                        </div>
                        <Hr spacing={Hr.spacings.BIG} />
                        <div className={cx(spacing.big, styles.mobilePrimaryCta)}>
                            <div className={spacing.big}>
                                <TableOfContents
                                    isId
                                    header={t('contentsTitle')}
                                    tableOfContents={tableOfContents}
                                    data-qa="table-of-contents-mobile"
                                />
                            </div>
                        </div>
                        {language.content && (
                            <div
                                className={spacing.super}
                                data-qa="markdown"
                                itemProp="articleBody"
                                data-layout="article-content"
                            >
                                <MDXRenderer>{language.content}</MDXRenderer>
                            </div>
                        )}
                        <div className={cx(spacing.big, styles.mobilePrimaryCta)}>
                            <Hr spacing={Hr.spacings.BIG} />
                            <TableOfContents
                                header={t('otherLegals')}
                                tableOfContents={pages}
                                data-qa="table-of-contents-desktop"
                            />
                        </div>
                    </div>
                </div>
            </Template.Grid>
        </article>
    );
};

interface ILegalProps {
    appStoreConfig: IAppStoreConfig;
    cookiePolicyConfig: {
        moreInfoLinkUrl: string;
        enabled: boolean;
        language: Record<string, string>;
    };
    language: Record<string, string>;
    pageConfig: {
        wordCount: number;
        timeToRead: number;
        displayDescription: boolean;
        ctaUrl: string;
        tableOfContents: { items: unknown[] };
    };
    isWebView: boolean;
    version: string;
    effectiveFrom: string;
    pages: unknown[];
}

const Legal = ({
    appStoreConfig,
    cookiePolicyConfig,
    language,
    pageConfig,
    isWebView = false,
    version,
    effectiveFrom,
    pages = [],
}: ILegalProps): ReactElement => {
    const targetRef = React.createRef<HTMLDivElement>();
    const markdownOverrides = getMarkdownOverrides();
    const mdxComponents = {
        ...ShortCodes,
        ...ArticleTools,
        ...markdownOverrides,
    };
    const { items } = pageConfig.tableOfContents;
    return (
        <MDXProvider components={mdxComponents}>
            {isWebView ? (
                <LegalArticle
                    isWebView={isWebView}
                    language={language}
                    targetRef={targetRef}
                    tableOfContents={items}
                    timeToRead={pageConfig.timeToRead}
                    version={version}
                    effectiveFrom={effectiveFrom}
                    pages={pages}
                />
            ) : (
                <Template appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
                    <div className={styles.readingProgressBar}>
                        <ScrollProgressBar target={targetRef} />
                    </div>
                    <LegalArticle
                        isWebView={isWebView}
                        language={language}
                        targetRef={targetRef}
                        tableOfContents={items}
                        timeToRead={pageConfig.timeToRead}
                        version={version}
                        effectiveFrom={effectiveFrom}
                        pages={pages}
                    />
                </Template>
            )}
        </MDXProvider>
    );
};

export default Legal;
