import React from 'react';

const ProtectDarkWebCalculator = () => (
    <iframe
        src="https://clearscore205.outgrow.us/clearscore205-26"
        title="How much are you worth on the dark web?"
        scrolling="yes"
        width="100%"
        height="950"
    />
);

export default ProtectDarkWebCalculator;
