import React from 'react';
import cx from 'classnames';

import spacingStyles from '../../spacing.module.css';
import styles from './styles.module.css';

const spacings = {
    NONE: 'none',
    TINY: 'tiny',
    MEDIUM: 'medium',
    BIG: 'big',
    HUGE: 'huge',
    SUPER: 'super',
} as const;

interface IHrProps {
    spacing: (typeof spacings)[keyof typeof spacings];
    hideM?: boolean;
    hideL?: boolean;
}

const Hr = ({ hideM = false, hideL = false, spacing = spacings.SUPER }: IHrProps): React.ReactElement => (
    <hr
        className={cx(spacingStyles[spacing], styles.hr, {
            [styles.hideM]: hideM,
            [styles.hideL]: hideL,
        })}
    />
);

Hr.spacings = spacings;

export default Hr;
