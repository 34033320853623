import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const ScrollProgressBar = ({ target }) => {
    const [progress, setProgress] = useState(0);
    const scrollListener = () => {
        if (!target.current) {
            return;
        }
        const quaterViewPort = 1.25;
        const element = target.current;
        const totalHeight = element.clientHeight - window.innerHeight / quaterViewPort;
        const windowScrollTop =
            window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (windowScrollTop === 0) {
            setProgress(0);
        }

        if (windowScrollTop > totalHeight) {
            setProgress(100);
        }

        setProgress(((windowScrollTop / totalHeight) * 100).toFixed(1));
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollListener);
        return () => window.removeEventListener('scroll', scrollListener);
    });

    return <div data-qa="progress-bar" className={styles.progressBar} style={{ width: `${progress}%` }} />;
};

ScrollProgressBar.propTypes = {
    target: PropTypes.object.isRequired,
};

export default ScrollProgressBar;
