import BalanceTransferCalculator from './components/balance-transfer-calculator';
import BalanceTransferCalculatorAU from './components/balance-transfer-calculator-au';
import DebtConsolidationCalculator from './components/debt-consolidation-calculator';
import DebtConsolidationCalculatorAU from './components/debt-consolidation-calculator-au';
import MoneyGoalQuiz2020 from './components/money-goal-quiz-2020';
import PersonalityQuiz from './components/money-personality-quiz';
import CarInsuranceCalculator from './components/car-insurance-calculator';
import CarInsuranceQuiz from './components/car-insurance-quiz';
import ProtectDarkWebCalculator from './components/protect-dark-web-calculator';
import CalconicCalculator from './components/calconic-calculator';

export default {
    BalanceTransferCalculatorAU,
    BalanceTransferCalculator,
    CalconicCalculator,
    DebtConsolidationCalculator,
    DebtConsolidationCalculatorAU,
    MoneyGoalQuiz2020,
    PersonalityQuiz,
    CarInsuranceCalculator,
    CarInsuranceQuiz,
    ProtectDarkWebCalculator,
};
