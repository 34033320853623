import React, { Fragment } from 'react';
import ShortCodes from '@clearscore/ui.shared.markdown-shortcodes';

const DebtConsolidationCalculator = () => (
    <Fragment>
        <div className="calconic-calculator" data-calculatorid="5e84d24d21addb0029181edb" />
        <ShortCodes.script src="https://cdn.calconic.com/beta/js/calconic.min.js" id="calconic_" />
    </Fragment>
);
export default DebtConsolidationCalculator;
