import React, { Fragment } from 'react';
import ShortCodes from '@clearscore/ui.shared.markdown-shortcodes';

const CarInsuranceCalculator = () => (
    <Fragment>
        <div className="calconic-calculator" data-calculatorid="5da6f02284946c001f1cb919" />
        <ShortCodes.script src="https://cdn.calconic.com/beta/js/calconic.min.js" id="calconic_" />
    </Fragment>
);

export default CarInsuranceCalculator;
