import React, { Fragment } from 'react';
import type { ReactElement } from 'react';
import ShortCodes from '@clearscore/ui.shared.markdown-shortcodes';

const VERSION = {
    STATIC: 'static',
    BETA: 'beta',
    LEGACY: 'legacy',
} as const;

type VersionValue = (typeof VERSION)[keyof typeof VERSION];

interface ICalconicCalculatorProps {
    id: string;
    version?: VersionValue;
}

const isAlphaNumeric = (str: string) => /^[a-zA-Z0-9]+$/.test(str);

const CalconicCalculator = ({ id, version = 'static' }: ICalconicCalculatorProps): ReactElement | null => {
    if (!isAlphaNumeric(id)) {
        return null;
    }

    return (
        <Fragment>
            <div className="calconic-calculator" data-calculatorid={id} data-id="calconic-calculator" />
            <ShortCodes.script src={`https://cdn.calconic.com/${version}/js/calconic.min.js`} />
        </Fragment>
    );
};

export default CalconicCalculator;
