import React, { Fragment } from 'react';
import ShortCodes from '@clearscore/ui.shared.markdown-shortcodes';

const DebtConsolidationCalculatorAU = () => (
    <Fragment>
        <div className="calconic-calculator" data-calculatorid="622585da9d9165002c0a068b" />
        <ShortCodes.script src="https://cdn.calconic.com/static/js/calconic.min.js" id="" calconic_ />
    </Fragment>
);
export default DebtConsolidationCalculatorAU;
