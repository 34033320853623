import React from 'react';

const MoneyGoalQuiz2020 = () => (
    <iframe
        src="https://clearscore205.outgrow.us/5dd5425f1f1d8c434ed9dd85"
        width="100%"
        height="600px"
        scrolling="yes"
        name="money-goal-quiz-2020"
        title="What should your 2020 money goal be? Take our quiz to find out."
    />
);

export default MoneyGoalQuiz2020;
