import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/ui.rainbow.text';
import Link from 'gatsby-link';

import styles from './styles.module.css';

const TableOfContents = ({ tableOfContents, isId, header }) =>
    tableOfContents ? (
        <div className={styles.container}>
            <Fragment>
                <div className={styles.title}>
                    <Text.H4 tag={Text.tags.H2}>{header}</Text.H4>
                </div>
                <ul>
                    {tableOfContents.map((item) => (
                        <li key={item.title} className={styles.menuItem}>
                            {isId ? (
                                <Text.Link isSimpleLink href={item.url?.replace(/(-)/g, '')}>
                                    {item.title}
                                </Text.Link>
                            ) : (
                                <div className={styles.categoryHeader}>
                                    <Link to={item.url}> {item.title}</Link>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </Fragment>
        </div>
    ) : null;

TableOfContents.propTypes = {
    tableOfContents: PropTypes.array,
    isId: PropTypes.bool,
    header: PropTypes.string,
};

TableOfContents.defaultProps = {
    tableOfContents: undefined,
    isId: false,
    header: '',
};

export default TableOfContents;
